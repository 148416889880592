import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
Vue.prototype.$envBaseUrl = process.env['VUE_APP_BASE_API']
//  引入路由配置
import router from '@/router/index'
Vue.config.productionTip = false

new Vue({
  //挂载路由对象
  router,
  render: h => h(App),
}).$mount('#app')
