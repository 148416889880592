import Vue from "vue";
import VueRouter from "vue-router";
// 注册 路由组建
Vue.use(VueRouter);
// 导入  首页模板
let home = () => { return import('@/pages/index') };
let is404 =() =>{return  import('@/pages/404')}
let  detail = () =>{ return import("@/pages/detail")}
let news =()=>{return import('@/pages/news')}
let newInfo =()=>{return import('@/pages/news/newsview/infoView')}
let organ = () =>{return import('@/pages/organ')}
let member =() =>{ return import("@/pages/member")}
const routes = [
    {
        // 会匹配所有路径 
        path: '*',
        component:is404
    },
    {
        path:'',
        redirect:'/home'
    },
    {
        path:'/member',
        component:member

    },
    {
        path: '/home',
        component: home
    },
    {
        path:"/detail",
        component:detail,
        name:"detail"
       
    },{
        path:'/news/:id/:title',
        component:news,
        name:'news'
    },
    {
        path:'/newInfo',
        component:newInfo
    },
    {
        path:"/organ",
        component:organ
    }

]
let router = new VueRouter({
    routes,
    mode: 'hash'
})
export default router